.Home {
    /* background-image: url('../../Assets/Images/billboard_bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center 0px; */
    /* filter: contrast(1.2); */

    width: 100%;
    height: 100%;
    display: block;
    overflow: hidden;
    background: var(--light);
    position: relative;
    background-color: var(--light)
}

.Home .Container {
    align-items: flex-end;
}

.Home.Page {
    padding: 0;
}

.t-right {
    width: 100%;
    height: 68%;
    display: none;
    overflow: hidden;
    background: var(--accent);
    position: absolute;
    bottom: 0;
    right: 0;
    transform-origin: top right;
    transform: rotate(30deg);
    z-index: 1;
}

.billboard {
    position: absolute;
    background-image: url(../../Assets/Images/billboard.png);
    background-repeat: no-repeat;
    background-size: cover;
    /* background-position: center 0px; */
    height: 90%;
    aspect-ratio: 1330/951;
    /* right: -128px;
    bottom: 150px;
    height: 951px;
    width: 1330px; */
    z-index: 3;
    /* max-width: 1000px;
    max-height: 800px; */
    bottom: 0;
    right: 0;
}


@media only screen and (max-width : 1920px) {
    .billboard {
        /* height: 618px;
        width: 864px;
        bottom: 0px;
        right:-80px */
        /* right:-146px; */
    }
}

@media only screen and (max-width : 1600px) {
    .billboard {
        height: 85%;
        /* right:-146px; */
    }
}

@media only screen and (max-width : 1500px) {
    .billboard {
        height: 80%;
        /* right:-146px; */
        /* bottom:-40px; */
    }
}

@media only screen and (max-width : 1340px) {
    .billboard {
        height: 75%;
        /* right:-146px; */
        /* bottom:-40px; */
    }
}

@media only screen and (max-width : 1000px) {
    .billboard {
        height: 60%;
        /* right:-146px; */
        /* bottom:-40px; */
    }
}

@media only screen and (max-width : 720px) {
    .billboard {
        right: -1%;
        height: 70%;
        filter: brightness(0.4);
    }
}

@media only screen and (max-width : 600px) {
    .billboard {
        right: -15%;
        height: 70%;
    }
}


@media only screen and (max-width : 500px) {
    .billboard {
        right: -70%;
        height: 80%;
    }

}

/*@media only screen and (max-width : 1280px) {
    .billboard {
        height: 55vw;
        width: 65vw;
    }
}

@media only screen and (max-width : 1000px) {
    .billboard {
        height: 50vw;
        width: 60vw;
    }
} */


@media only screen and (max-width : 960px) {
    .Home {
        /* background-image: url('../../Assets/Images/billboard_bg2.png'); */
        background-repeat: no-repeat;
        background-size: cover;
    }
}