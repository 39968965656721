.Music {
    /* background-color: #fff; */
}

.Music.Page {
    height: auto;
    background-color: var(--accent);
    overflow-x: hidden;
}

.Extra.Page {
    height: 200px;
    min-height: 100px;
    padding: 0;
}

.Album {
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    width: 100%;
}

.vidContainer {
    margin-top: 80px;
    padding: 32px 0;
    background-color: var(--dark);
}

iframe {
    min-height: 720px;
}

@media only screen and (max-width : 1280px) {
    .Album {
        display: block;
    }

    .Extra.Page {
        height: auto;
    }

}

@media only screen and (max-width : 768px) {
    iframe {
        min-height: 500px;
    }

    .vidContainer h4 {
        right: 0;
        text-align: right;
        padding-right: 32px;
    }
}

@media only screen and (max-width : 540px) {
    iframe {
        min-height: 300px;
    }

}