@import url('https://fonts.googleapis.com/css?family=Satisfy|Source+Sans+Pro');


@font-face {
  font-family: 'Docktrin';
  src: url('./Assets/Fonts/Docktrin.eot');
  src: url('./Assets/Fonts/Docktrin.eot?#iefix') format('embedded-opentype'),
    url('./Assets/Fonts/Docktrin.woff2') format('woff2'),
    url('./Assets/Fonts/Docktrin.woff') format('woff'),
    url('./Assets/Fonts/Docktrin.ttf') format('truetype'),
    url('./Assets/Fonts/Docktrin.svg#Docktrin') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


:root {
  --light: #efe3d4;
  --dark: #232021;
  --accent: #917b56;
  --accent-alt: #764e2a;
}

* {
  font-family: 'Source Sans Pro', sans-serif;
}

html {
  background-color: var(--dark);
  color: var(--dark);
  height: 100%;
}

body,
#root,
.App {
  height: 100%;
}

a {
  text-decoration: none;
  color: inherit
}

/* HTML: <div class="loader"></div> */
.loader {
  width: 50px;
  padding: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: var(--accent-alt);
  --_m: 
    conic-gradient(#0000 10%,#000),
    linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
          mask: var(--_m);
  -webkit-mask-composite: source-out;
          mask-composite: subtract;
  animation: l3 1s infinite linear;
}
@keyframes l3 {to{transform: rotate(1turn)}}

button {
  background-color: var(--dark);
  color: #fff;
  width: 160px;
  height: 30px;
  border-radius: 4px;
  font-size: 16px;
  /* border: 1px solid #fff; */
  border: none;
  font-weight: bolder;
}

h1 {
  font-family: 'Docktrin';
  font-weight: 500;
  font-size: 86px;
  margin: 0;
  color: var(--dark);
  line-height: 1em;
  /* -webkit-transform: scale(0.85, 1.3); */
  /* transform: scale(0.85, 1.3); */
  letter-spacing: 6px;
  /* text-shadow: -1px -1.5px #fff;*/
}

h2 {
  font-weight: 300;
  font-size: 24px;
  margin: 0;
  color: var(--dark);
}

h3 {
  font-family: 'Docktrin';
  font-weight: 500;
  font-size: 3em;
  color: var(--dark);
  letter-spacing: 0.1em;
  margin: 0;
  text-transform: lowercase;
  padding: 0 0 64px 0;
}

h3.light {
  color: var(--light);
}

h4 {
  font-weight: 600;
  font-size: 2em;
  color: #2b2b2b;
  letter-spacing: 0.1em;
  margin: 0;
  text-transform: uppercase;
}

h5 {
  font-weight: 200;
  font-size: 1em;
  color: #fff;
  margin: 12px 0;
}

h6 {
  text-transform: uppercase;
  font-style: italic;
  padding: 0;
  margin-top: 0;
  color: #2b2b2b;
  font-size: 1em;
}

.Container {
  width: 100%;
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Container.Full {
  width: 1920px;
  margin: 0 auto
}

.wrapper {
  width: 1360px;
  margin: 0 auto;
  height: 100%;
  position: relative;
  z-index: 3;
  padding: 0;
}

.wrapper-full {
  width: 100%;
  margin: 0 auto;
  height: 100%;
  position: relative;
  z-index: 3;
  padding: 0;
}

.col {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  flex-direction: row;
}

.between {
  justify-content: space-between;
}

.shape {
  width: 550px;
  height: 100px;
  transform: skewX(-45deg) scaleY(cos(-45deg));
  position: absolute;
  background-color: var(--dark);
  z-index: 1;
  margin-left: -36px;
  margin-top: -16px;
  overflow: hidden;
}

#shape2 {
  right: -34px;
  top: -100px;
}

.Page {
  height: 100%;
  width: 100%;
  padding: 156px;
  box-sizing: border-box;
}

.MoreInfo button {
  background-color: var(--accent);
  color: var(--light);
  width: 160px;
  height: 40px;
  border-radius: 4px;
  font-size: 16px;
  border: none;
  font-weight: bolder;
  margin: 26px;
  text-transform: lowercase;
  padding-top: 6px;
}

.MoreInfo button:hover {
  background-color: var(--accent-alt);
  border: 1px solid var(var(--dark))
}

.Button {
  cursor: pointer;
  -webkit-transition: background-color 0.2s;
  -moz-transition: background-color 0.2s;
  -o-transition: background-color 0.2;
  transition: background-color 0.2s;
}

.Button:hover {
  background-color: var(--accent-alt);
  /* color: #000; */
}

.Social li,
.NavBar li {
  cursor: pointer;
  -webkit-transition: color 0.8s;
  -moz-transition: color 0.8s;
  -o-transition: color 0.8s;
  transition: color 0.8s;
}

.Social li:hover,
.NavBar li:hover,
.Header.Alt .NavBar li:hover,
.Header.Alt .Social li:hover {
  color: var(--accent-alt);
}

.player-container {
  flex-wrap: wrap-reverse;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 90px;
}

.inline-player {
  max-width: 100% !important;
}

.material-icons {
  color: var(--light) !important;
}


@media only screen and (min-width : 1920px) {
  .wrapper-full {
    width: 1920px;
  }

  .wrapper {
    width: 1800px;
  }
}

@media only screen and (max-width : 1500px) {
  #shape2 {
    right: 32px;
  }
}

@media only screen and (max-width : 1360px) {
  .wrapper {
    width: 1100px;
  }
}

@media only screen and (max-width : 1280px) {
  .wrapper {
    width: 960px;
  }

  h1 {
    font-size: 100px
  }

  h2,
  button,
  .Button {
    font-size: 24px;
    margin: 0
  }

  h3 {
    font-size: 2.6em;
  }

  .Page {
    padding: 96px;
  }

  .Page {
    /* height:720px */
  }

  .Button {
    width: 128px;
    height: 40px;
  }

  .MoreInfo button {
    width: 140px;
  }

  #shape1 {
    margin: 0 auto;
    left: 0;
    right: 0;
    margin-top: -18px;
  }

}

@media only screen and (max-width : 1000px) {
  .wrapper {
    width: 900px;
  }
}

@media only screen and (max-width : 960px) {
  .wrapper {
    width: 700px;
  }

  h1 {
    font-size: 80px
  }

  h2,
  button,
  .Button {
    font-size: 18px
  }

  h3 {
    font-size: 3em;
  }

  .Page {
    padding: 64px;
  }

  .Button {
    width: 96px;
    height: 30px;

  }
}

@media only screen and (max-width : 768px) {
  #shape2 {
    transform: none;
    right: 0;
  }
}

@media only screen and (max-width : 720px) {
  .wrapper {
    width: 96%;
  }

  h1 {
    font-size: 60px
  }

  h2,
  button,
  .Button {
    font-size: 14px
  }

  h3 {
    font-size: 2.5em;
    text-align: center;
    padding: 36px 0;
  }

  .Page {
    padding: 32px;
  }

  .Button {
    height: 30px;
    font-size: 16px;
  }

  .Page {
    /* height:540px */
  }


}


@media only screen and (max-width : 650px) {
  .shape {
    width: 80%;
  }

  .AlbumTitle h4,
  .vidContainer h4 {
    font-size: 1.5em;
  }

  #shape2 {
    margin-top: 0px;
    height: 70px;
  }
}

@media only screen and (max-width : 540px) {
  .Page {
    padding: 16px;
  }

  .AlbumTitle h4,
  .vidContainer h4 {
    font-size: 1.2em;
  }

  .shape {
    transform: none;
    height: 80px;
  }
}