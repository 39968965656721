.AlbumRelease{
    z-index: 2;
}

.AlbumRelease h6{
    text-transform: uppercase;
    font-style: italic;
    padding: 0;
    margin-top: 0;
    color: var(--light);
}