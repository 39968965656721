.DrawerContainer{
    position: fixed;
    left: 24px;
    top: 24px;
    display: none;
    z-index: 99;
}

.Drawer{
    width: 0;
    display: none;
    background-color: #e9e9e9;
    height:100%;
    overflow: hidden;
    transition-property: width;
    transition-duration: 0.7s;
}

.Drawer.Active{
    width: 300px;
    display: block;
}

.Drawer.Active .NavBar{
    display: block;
}

.Drawer.Active .NavBar ul{
    list-style: none;
    padding: 0 0 0 24px;
}

.Drawer.Active .NavBar li{
    margin: 12px 0;
    font-size: 20px;
    color: #000;
    display: block;
    padding: 6px;
}

.DrawerIcon .material-icons{
    background-color: #000
}
@media only screen and (max-width : 720px) {
    .DrawerContainer {
        display: block
    }
}
