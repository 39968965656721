.Button{
    background-color: var(--dark);
    color: var(--light);
    width: 200px;
    height: 40px;
    border-radius: 2px;
    font-size: 26px;
    letter-spacing: 2px;
    border: 4px solid var(--dark);
    font-family: 'Docktrin';
    transition: background-color 0.7s !important;
}