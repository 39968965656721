.LoginContainer{
    margin: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.LoginContainer input{
    min-width: 300px;
    padding: 8px;
    margin: 8px;
}

.LoginContainer h1.AdminLogo {
    font-size: 5em;
}

.LoginContainer button{
    margin: 8px;
    cursor: pointer;
}

.LoginErr{
    color: #d32f2f
}