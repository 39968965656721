.AlbumTitle{
    z-index: 2;
    margin-top:16px;
}

.AlbumTitle h4, .vidContainer h4{
    color: var(--light) !important;
    font-family: 'Docktrin';
    text-transform: lowercase;
    font-weight: lighter;
    line-height: 0.6em;
}

.vidContainer h4{
    position: absolute;
    right: -32px;
    top: -64px;
    z-index: 2;
    width: 550px;
    text-align: center;
}