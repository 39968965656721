.EventsWrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 511px;
}

.EventsHeader{
    margin: 0 0 54px 0;
    text-align: center;
}

.EventsDates{
    display: flex;
    /* justify-content: space-evenly; */
    width: 1280px;
}

.NoEvents{
    text-align: center;
    width: 100%;
    font-size: 2em;
}

.carousel{
    width: 100%;
    min-height: 400px;
}

.horizontalSlider___281Ls{
    height:100%;
    margin-bottom: 24px;
}

.buttonNext___2mOCa.carousel__next-button{
    float:right
}

@media only screen and (max-width : 1920px) {
    /* .EventsDates {
        width: 1366px;
    } */
}

@media only screen and (max-width : 1280px) {
    .EventsDates {
        /* justify-content: space-evenly; */
        width: 960px;
    }
}

@media only screen and (max-width : 960px) {
    .EventsDates {
       display: block;
        width: 80%;
    }
}

@media only screen and (max-width : 720px) {
    .horizontalSlider___281Ls {
        /* height: 400px !important */
    }
    .buttonNext___2mOCa.carousel__next-button {
        float: inherit; 
        margin-bottom: 16px;
        width: 100%;
        height: 36px;
    }
    .buttonBack___1mlaL {
        float: inherit; 
        margin-bottom: 16px;
        width: 100%;
        height: 36px;
    }
}