.NotFound.Page{
    display: flex;
    height: 100%;
    background-color: #ffffff;
    flex-flow: wrap;
    padding: 0;
    justify-content: center;
    align-items: center;
}

.header{
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    background-color: #000;
}

.header h1.AdminLogo{
    cursor: pointer;
}

.main h2{
    font-weight: bold;
    font-size: 86px;
    margin: 0;
    color: #fff;
    border-right: 1px solid #999;
    padding: 16px;
    color: #2d2d2d;
}

.text {
    font-size: 24px;
    padding: 16px;
}