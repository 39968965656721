.Header{
    margin: 32px 0px;
    /* padding: 0px 40px; */
    position: fixed;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    transition-property: color, margin, padding, background-color;
    transition-duration: 0.7s;
    z-index: 99;
}

.Header.Alt{
    margin: 0px;
    background-color: var(--light);
}

.Header.Alt .NavBar li, .Header.Alt .Social li {
    color: var(--dark);
}
  
@media only screen and (max-width : 720px) {
    .Header {
      display: none;
    }
}