.Footer{
    min-height:200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}

.Footer .Social{
    display: block !important;
}

.Footer a{
    text-align: center;
    color: var(--light);
}


.Footer .Social li {
    display: inline-block;
    color: var(--light);
}