.AlbumArt{
    width: 434px;
    height: 434px;
    border: 12px solid var(--dark);
    box-sizing: border-box;
}

.AlbumArt img{
    width: 100%;
    height:100%;
}


@media only screen and (max-width : 1280px) {
    .AlbumArt{
        margin: 0 auto;

    }
}

@media only screen and (max-width : 540px) {
    .AlbumArt{
        width: 76%;
        height: 76%;
    }
}
