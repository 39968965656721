.Artwork{
    flex: 0.1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center
}

.Artwork img{
    height: 82px;
    width: 82px;
}

@media only screen and (max-width : 720px) {
    .Artwork img{
        height: 48px;
        width: 48px;
    }
}