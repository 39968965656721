.AlbumInfo{
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.PlayerList{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 36px;
    /* min-width: 350px; */
    flex: 0.95 1;
    background-color: var(--dark);
    height: 400px;
}


@media only screen and (max-width : 1280px) {
    .AlbumInfo{
        display: block;
        text-align: center;
    }
    .PlayerList{
        margin: 48px 0 0 0;
    }
}

@media only screen and (max-width : 480px) {
    .PlayerList{
        padding: 0 8px;
        min-width: 0;
    }

}