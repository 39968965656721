.About.Section {
    display: flex;
    flex: 0.5;
}

.About.Section.Reverse{
    justify-content: flex-end;
}

.About.Section.Column{
    flex-direction: column;
}

.AboutWrapper{
    display: flex;
    width:100%;
    transition-property: opacity;
    transition-duration: 1.5s;
    opacity: 0
}

.AboutWrapper.In{
    opacity: 1
}

.Photo.Large{
    flex: 0.5;
}

.Photo.Large img{
    height: 380px;
    object-fit: cover;
    width: 100%;
    /* -webkit-filter: grayscale(100%);
    filter: grayscale(100%); */
}

.Photos.Small{
    flex: 0.5;
    display: flex;
    flex-direction: row;
}

.Photos.Small img{
    height: 230px;
    object-fit: cover;
    width: 50%;
    /* -webkit-filter: grayscale(100%); 
    filter: grayscale(100%); */
}

.Text{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 78px;
    background: #fff url('../../Assets/Images/quote.png') no-repeat;
    background-size: 100px;
    background-position: left 60px top 120px;
    box-sizing: border-box;
}

.About.Section.Reverse .Text{
    background-position: left 60px top 100px;
}

.Text p{
    font-size: 1.3em;
    font-weight: 400;
    line-height: 1.9em;
    width: 440px;
}

.Text p.Endorsement{
    font-size: 1.5em;
    font-weight: bolder;
    text-transform: uppercase;
}

@media only screen and (max-width : 1280px) {
    .AboutWrapper{
        display: block;
    }
    .Photo.Large{
        /* width: 50%; */
    }
    .Photos.Small{
        /* width: 50%; */
    }
    .Text{
        width: 100%;
    }
    .Text p {
        width: 900px;
    }
}

@media only screen and (max-width : 960px) {
    .Text p {
        width: 700px;
    }
    /* .AboutWrapper{
        transition-property: none;
        transition-duration: unset;
        opacity: 1
    } */
}

@media only screen and (max-width : 720px) {
    .Photo.Large{
        width: 100%;
        flex: auto
    }
    .Text p {
        width: 100%;
    }
}

@media only screen and (max-width : 480px) {
    .Text{
        padding: 48px;
    }
    .Text p{
        font-size: 1em;
    }

}