#tomcolehenrybristow{
    background-color: rgb(16, 14, 14);
    padding: 0;
    position: relative;
    height: 100%;
    width: 100%;
}

#tomcolehenrybristow p{
    font-size: 20px;
    color: #fff;
    line-height: 1.6em;
    margin: 32px;
}

#tomcolehenrybristow p a{
    color:var(--accent)
}

.NavBar.opp li, .Social.opp li {
    color: var(--light) !important;
}
.Header.Alt .NavBar.opp li, .Header.Alt .Social.opp li {
    color: var(--dark) !important;
}

#vid-wrapper{
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
}

#content-wrapper{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

#content-container{
    max-width:1920px;
    padding:32px 12%;
    background-color: var(--dark);
}

#content-container .Signature{
    margin-top: 16px;
    width: 100%;
    display: block;
    text-align: center;
}

video{
    object-fit: cover;
    background-color: rgb(16, 14, 14);
    position: absolute;
    width: 100%;
    height: 100%;
    max-width: 1920px;
}

#watch{
    color: var(--accent);
    padding: 4px;
    cursor: pointer;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
    margin-top: 6px;
}

#showreel-background{
    object-fit: cover;
    background-color: rgb(16, 14, 14);
    position: absolute;
    width: 100%;
    height: 100%;
    max-width: 1920px;
    z-index: 1;
    background-image: url("../../Assets/Images/showreel_background.jpg");
    background-size: cover;
    background-position: center;
}

#content-image{
    width: 80%;
    object-fit: contain;
    padding: 48px;
    box-sizing: border-box;
}

.out{
    -webkit-animation: fadeOut 0.7s ease-in;
    -moz-animation: fadeOut 0.7s ease-in;
    animation: fadeOut 0.7s ease-in;
    -webkit-animation-fill-mode: forwards;
    z-index: 1;
}

.in{
    -webkit-animation: fadeIn 0.7s ease-in;
    -moz-animation: fadeIn 0.7s ease-in;
    animation: fadeIn 0.7s ease-in;
    -webkit-animation-fill-mode: forwards;
    z-index: 2;
}


#video-controls{
    position: absolute;
    bottom: 28px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
    box-sizing: border-box;
    z-index: 3;
}

#video-controls h3{
    padding: 0;
    font-size: 2em;
    color: #fff;
}

#video-controls h4{
    padding: 0;
    font-size: 1.3em;
    color: #fff;
    font-weight: 100; 
}

#watch-now.out{
    -webkit-animation: fadeOut 0.7s ease-in;
    -moz-animation: fadeOut 0.7s ease-in;
    animation: fadeOut 0.7s ease-in;
    -webkit-animation-fill-mode: forwards;
}

#watch-now{
    border-radius: 2px;
    padding: 12px;
    height: 40px;
    margin-top: 16px;
    font-size: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 1px solid;
    background-color: #121212;
    transition-property: color, margin, padding, background-color, border-color;
    transition-duration: 0.7s;
}

#watch-now:hover{
    background-color: var(--accent);
    border-color: var(--accent);
}

@media only screen and (max-width : 720px) {
    #video-controls h3{
        font-size: 1.7em;
    }
    
    #video-controls h4{
        font-size: 1em;
    }
    #content-container{
        padding:16px;
    }
    #content-image{
        padding:12px;
    }
}

@media only screen and (max-width : 480px) {
    #content-container{
        padding:0;
    }
    #content-image{
        padding:4px;
    }
    #video-controls h3{
        font-size: 1.4em;
    }
    #tomcolehenrybristow p{
        font-size: 1em;
        text-align: center;
    }
    #video-controls h4{
        font-size: 0.7em;
    }
    #showreel-background{
        background-position: left -170px top;
    }
}

@-webkit-keyframes fadeOut {
    from { opacity: 1; }
    to { opacity: 0; }
}

@-moz-keyframes fadeOut {
    from { opacity: 1; }
    to { opacity: 0; }
}

@keyframes fadeOut {
    from { opacity: 1; }
    to { opacity: 0; }
}

@-webkit-keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

@-moz-keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}