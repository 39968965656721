.Find h5{
    font-size: 1.4em;
    font-weight: 900;
    /* font-size: 4em; */
    color: #2b2b2b;
    text-transform: uppercase;
    padding: 4px 0;
    color:var(--light);
}

.Find a{
    text-decoration: underline;
    font-style: italic;
}