.PlayerArrow{
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom:16px;
    right:16px;
    height:50px;
    width: 50px;
    border-radius: 32px;
    background-color: var(--accent-alt);
    cursor: pointer;
    z-index: 10;
}



@media only screen and (max-width : 720px) {
    .PlayerArrow {
        border-radius: 24px;
        height:48px;
        width: 48px;
        bottom:16px;
    }
    .PlayerArrow.active{
        bottom:96px;
    }
}