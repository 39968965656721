.NavBar{
    display: flex;
    align-items: center;
}

.NavBar ul{
    list-style: none;
    margin: 0;
    padding: 0;
}

.NavBar li {
    display: inline-block;
    color: var(--dark);
    font-size: 18px;
    text-transform: uppercase;
    padding: 8px 16px;
    font-weight: bold;
}

.NavBar li:first-child{
    padding-left: 0;
}

@media only screen and (max-width : 960px) {
    .NavBar{
        margin-right: 0;
    }
    .NavBar ul {
        padding: 0
    }
    .NavBar li {
        font-size: 18px;
    }
}

@media only screen and (max-width : 720px) {
    .NavBar {
        display: none
    }
}

