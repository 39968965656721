.TrackListing ul{
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-height: 320px;
}

.TrackListing li{
    padding: 6px 0px;
    cursor: pointer;
    display: flex;
    align-items: center;
    color:var(--light)
}

.TrackListing li i {
    margin-right: 12px;
}

.TrackNo{
    padding: 0px 8px 0px 0px;
}

.BandCampIcon{
    margin-left: auto;
}

@media only screen and (max-width : 768px) {
    .vidContainer{
        margin-top: 140px !important;
    }
}
@media only screen and (max-width : 700px) {
    .TrackListing ul{
        flex-wrap:nowrap;
        max-height: 100%;
    }
    .PlayerList{
        margin-top: 0px;
        margin-bottom: 16px;
        padding: 16px 0;
        height: 100%;
    }
    .vidContainer {
        margin-top: 100px !important;
    }
}