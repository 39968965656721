.TourEvent{
    color: #2b2b2b;
    width: 280px;
    padding: 20px;
    box-sizing: border-box;
    -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadein 1s; /* Firefox < 16 */
        -ms-animation: fadein 1s; /* Internet Explorer */
         -o-animation: fadein 1s; /* Opera < 12.1 */
            animation: fadein 1s;
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

.TourInfo{
    border-bottom: 2px solid;
    border-top: 2px solid;
    padding: 12px 0;
    min-height: 280px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.Month{
    text-transform: uppercase;
    font-size: 2rem;
    font-weight: bolder;
}

.Day{
    text-transform: uppercase;
    font-size: 3.2rem;
    font-weight: bolder;
    padding-bottom: 12px;
    line-height: 0.8em;
}

.Venue{
    font-size: 1.1rem;
    padding: 12px 0;
    text-transform: uppercase;
    font-weight: bolder;
    min-height: 60px;
}

.Address{
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    line-height: 1.8em;
    max-height: 3.6em;
    min-height: 3.6em;
}

.Address p{
    margin: 0;
}

.Time{
    font-style: italic;
}

.slideHorizontal___1NzNV{
    height: 100% !important;
    padding-bottom: 0 !important;
}

.slideInner___2mfX9{
    position: relative !important;
}

.sliderAnimation___300FY, .carousel__slider-tray-wrapper{
    height: 100%;
}

@media only screen and (max-width : 1280px) {
    .TourEvent {
        width: 240px
    }
    .Venue{
        min-height: 60px
    }
}

@media only screen and (max-width : 960px) {
    .TourEvent {
        width: 100%;
        /* margin-bottom: 64px; */
    }
    .Venue{
        min-height: 20px
    }
}