.SongInfo{
    display: flex;
    flex-direction: column;
    color: var(--light);
    justify-content: center;
    flex: 0.6
}

.SongInfo h3{
    margin: 20px 0 0 0;
    padding: 0 0 8px 0;
    font-size: 1.7em;
    color: var(--light);
}

.SongInfo h4{
    margin: 0;
    font-style: italic;
    font-size: 1em;
}

@media only screen and (max-width : 720px) {
    .SongInfo h3{ 
        font-size: 1.2em;
    }
    
}

@media only screen and (max-width : 480px) {
    .SongInfo h3{ 
        font-size: 1em;
    }
    
}