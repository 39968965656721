.TitleContainer{
    display: flex;
    height: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* min-width: 720px; */
    width: 360px;
    position: absolute;
    left: 0;
}

.Title{
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 16px;
}

#circle1{
    height: 350px;
    width: 350px;
    background-color: var(--dark);
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

#circle2{
    height: 332px;
    width: 332px;
    background-color: var(--dark);
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    stroke: var(--light);
    border: 4px solid var(--light);
    flex-direction: column;
}

#circle2 h1{
    color: var(--light);
    text-align: center;
    font-size: 80px;
    line-height: 0.8em;
}

.arrow-down{
    position: absolute;
    bottom: 60px;
    left: 0;
    right: 0;
    display: none;
    text-align: center;
    justify-content: center;
    align-items: center;
    z-index: 9;
    animation: moveUpDown 2.5s ease-in-out infinite;
}

.arrow-down img{
    height: 140px;
}

@keyframes moveUpDown {
    0%, 100% {
      bottom: 60px; /* Start and end position */
    }
    50% {
      bottom: 80px; /* Middle position */
    }
  }

@media only screen and (max-width : 1340px) {

    #circle1{
        height: 300px;
        width: 300px;
    }
    #circle2{
        height: 282px;
        width: 282px;
    }

    #circle2 h1{
        font-size: 60px;
    }

    .Tagline h2{
        font-size: 18px !important;
    }
}

@media only screen and (max-width : 1280px) {
    .TitleContainer{
        justify-content: flex-start;
        align-items: flex-start;
        margin-top: 100px;
        width: 100%;
        min-width: 100%;
        z-index: 1;
        position: relative;
    }
}

@media only screen and (max-width : 1000px) {
    .TitleContainer{
        /* justify-content: center; */
        margin-top: 80px;
    }
    #circle1{
        height: 240px;
        width: 240px;
    }
    #circle2{
        height: 222px;
        width: 222px;
    }

    #circle2 h1{
        font-size: 48px;
    }

    .Tagline h2{
        font-size: 14px !important;
    }
}

@media only screen and (max-width : 720px) {
    .TitleContainer{
        margin-top: 20px;
        justify-content: center;
    }
    #circle1{
        height: 240px;
        width: 240px;
    }
    #circle2{
        height: 222px;
        width: 222px;
    }

    #circle2 h1{
        font-size: 48px;
    }

    .Tagline h2{
        font-size: 16px !important;
    }
}
    

@media only screen and (max-width : 540px) {
    .TitleContainer{
        margin-top: 60px;
    }
    /* #circle1{
        height: 200px;
        width: 200px;
    }
    #circle2{
        height: 182px;
        width: 182px;
    }

    #circle2 h1{
        font-size: 36px;
    }

    .Tagline h2{
        font-size: 11px !important;
    }*/

    #listen{
        display: none;
    } 
    .arrow-down{
        display: flex;
    }
}
    