.Player{
    width: 100%;
    height: 90px;
    background-color: var(--dark);
    display: flex;
    justify-content: space-evenly;
    position: fixed;
    bottom: 0;
    border-top: 5px solid var(--light);
    /* padding: 0px 36px; */
    box-sizing: border-box;
    z-index: 9;
}

#player{
    max-width: 100% !important;
}