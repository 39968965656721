.ContactForm{
    width: 60%;
}

.ContactForm input{
    width: 100%;
    display: block;
    margin: 12px 0;
    padding: 8px;
    border-radius: 8px;
    color: #2d2d2d;
    font-size: 18px;
    border-style: groove;
    box-sizing: border-box;
}

.ContactForm textarea{
    width: 100%;
    min-height: 200px;
    width: 100%;
    display: block;
    margin: 12px 0;
    padding: 8px;
    border-radius: 8px;
    /* border: 1px solid #2d2d2d; */
    color: #2d2d2d;
    font-size: 18px;
    box-sizing: border-box;
}

.SendBtn{
    margin-left:0 !important;
    font-family: 'Docktrin';
    font-size: 24px !important;
    cursor: pointer;
}

.Error{
    color:#ff0000;
    margin: 8px 0px;
}

.Success{
    color: green;
    margin: 8px 0px;
}

.Recapcha{
    margin: 12px 0;
}

@media only screen and (max-width : 960px) {
    .ContactForm {
        width: 90%;
    }
}