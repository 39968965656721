.AboutIntro{
    background-color: var(--dark);
    width: 100%;
    displaY: flex;
    justify-content: center;
}

.IntroText{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 156px 0;
}

.IntroText p, .IntroText span{
    font-size: 1.1em;
    font-weight: 400;
    line-height: 1.9em;
    width: 700px;
    color: var(--light);
}

.IntroText h3{
    color: var(--light);
}

.curveArea {
    background-color: var(--dark);
    width: 100%;
    height: 100%;
    margin: 0 auto;
}
  
 .mainBox {
    height: 100%;
    background: var(--light);
    overflow: hidden;
}
  
.curveSection {
    width: 200%;
    height: 100%;
    border-radius: 150% 150% 0 0;
    background-color: var(--dark);
    top: 25%;
    left: -50%;
    right: 0;
    position: relative;
}

.Signature{
    font-family: 'Satisfy', cursive;
    font-weight: 500;
    font-size: 56px;
    margin: 96px 0 0 0;
    color: var(--light);
    -webkit-transform: scale(0.85, 1.3);
}

@media only screen and (max-width : 720px) {
    .IntroText p, .IntroText span{
        width: 500px; 
    }
    .IntroText{
        margin:0;
    }
    .Signature{
        margin: 96px 0 36px 0;
    }
}

@media only screen and (max-width : 540px) {
    .IntroText p, .IntroText span{
        font-size: 1em;
        width: 400px; 
    }
}

@media only screen and (max-width : 480px) {
    .IntroText p, .IntroText span{
        font-size: 1em;
        width: 300px; 
    }
    .curveSection {
        border-radius: 0;
    }
}

@media only screen and (max-width : 320px) {
    .IntroText p, .IntroText span{
        font-size: 0.8em;
        width: 200px; 
    }
}