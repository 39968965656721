.Admin.Page{
    background-color: #fff;
    height: 100%;
}

.AdminEvents.Page{
    background-color: #000;
    height: 100%;
}

.AdminEvents.Page h3{
    color:#fff;
}

.InputWrapper{
    margin: 16px 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.TextArea{
    width: 50%;
    min-height: 200px;
    padding: 8px;
}

.InputText{
    width: 50%;
    min-height: 30px;
}

.Save{
    position: fixed;
    bottom: 24px;
    right: 24px;
    width: 76px;
    height: 76px;
    border-radius: 38px;
    background-color: #43a047;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.DeleteBtn{
    margin:16px 0;
    width: 48px;
    height: 48px;
    border-radius: 24px;
    background-color: #d32f2f;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

h1.AdminLogo{
    font-size: 2em;
    letter-spacing: normal;
    padding: 12px;
    color: var(--light);
}

.EventWrapper{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
    flex-direction: row;
}

.EventRow{
    color: #fff;
    padding: 16px;
    border: 1px solid;
    margin: 8px;
    box-sizing: border-box;
    flex: 0 calc(25% - 16px);
}

.EventRow.Add{
    justify-content: center;
    min-height: 300px;
    align-items: center;
    display: flex;
    cursor: pointer;
}

.EventBlock{
    display: block;
}

.react-datepicker__input-container input{
    min-height: 30px;
}

.EventRow .InputText, .react-datepicker__input-container input{
    width: 100%;
}


@media only screen and (max-width : 720px) {
    .TextArea{
        width: 100%;
    }

    .InputText{
        width: 100%;
    }

    .EventWrapper {
        flex-direction: column;
    }

    .LoginContainer input{
        min-width: 100px !important;
    }
}