.Social{
    
}

.Social ul{
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: center;
}

.Social li {
    display: inline-block;
    color: var(--dark);
    font-size: 24px;
    padding: 10px 12px 10px 8px;
}

.Social li:last-child{
    padding-right: 0;
}

@media only screen and (max-width : 960px) {
    .Social li {
        font-size: 18px;
    }
}

@media only screen and (max-width : 720px) {
    .Social {
        display: none
    }
}